<template>
    <div class="blog-row-items">
        <div class="blog-row-items-tag">
            <router-link :to="{ name: 'ApplicationNews' , params: { category_id: cat, }}" class="blog-row-items-title"><span>{{title}}</span></router-link>
            <router-link :to="{ name: 'ApplicationNews' , params: { category_id: cat, }}" class="blog-row-items-more">بیشتـر</router-link>                
        </div>

        <slot v-if="loading">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>

        <div v-if="!loading && slider.items" class="blog-row-items-row">
            <div v-for="item in slider.items.data" :key="item.id" class="blog-row-item">
                <router-link :to="{ name: 'ApplicationNewsContent' , params: { id: item.id, }, query: { subject: 'tgju-news-' + item.id }}">
                    <div class="blog-row-item-img" :style="'background-image: url('+item.image+');'"></div>
                </router-link>
                <router-link :to="{ name: 'ApplicationNewsContent' , params: { id: item.id, }, query: { subject: 'tgju-news-' + item.id }}" class="blog-row-item-title">{{item.title}}</router-link>
            </div> 
        </div>  
        
    </div>    
</template>
    
<script>
    export default {
        name: 'newsRow',
        props: ['cat', 'count', 'title', 'type'],
        components: {},
        data() {
            return {
                slider:[],
                slider_cat:0,
                loading:false
            }
        },
        mounted() {
            this.getSlider();
        },
        computed: {},
        methods: {
            getSlider(){
                this.loading = true;
                $.ajax({
                    url: ( this.type == 'slug' ? 'https://api.tgju.org/v1/news/list?limit='+this.count+'&tag='+this.cat : 'https://api.tgju.org/v1/news/list?limit='+this.count+'&category_id='+this.cat ),
                    method: 'get',
                    complete: (res) => {
                        this.slider = res.responseJSON.response;  
                        this.slider_cat = Object.keys(this.slider.categories)[0];
                        this.loading = false;
                    }
                });
            },
        },
    }
</script>  